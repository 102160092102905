<template>
  <div class="wrapper position-relative">
    <div class="logo">
      <img src="./images/momo_index_logo.png" />
    </div>
    <div class="bg-top">
      <div class="title">
        <img src="./images/momo_index_bg_title.png" />
      </div>
    </div>

    <div class="bg-bottom">
      <div class="layer">
        <div class="layer-point">
          <div class="point-title">累積點數</div>
          <div class="point">{{ availablePoints }}</div>
        </div>
        <router-link :to="{ name: 'LiffHuggiesMomoRedeem' }">
          <div class="button">
            <div>我要兌換</div>
            <img
              class="button-caret"
              src="./images/momo_index_button_caret.png"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'LiffHuggiesMomoRedeemRecord' }">
          <div class="button">
            <div>兌換紀錄</div>
            <img
              class="button-caret"
              src="./images/momo_index_button_caret.png"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'LiffHuggiesMomoPointRecord' }">
          <div class="button">
            <div>點數紀錄</div>
            <img
              class="button-caret"
              src="./images/momo_index_button_caret.png"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'LiffHuggiesMomoProfile' }">
          <div class="button">
            <div>兌獎資訊填寫</div>
            <img
              class="button-caret"
              src="./images/momo_index_button_caret.png"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      availablePoints: "liffHuggies/availablePoints",
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions({
      fetchAvailablePoints: "liffHuggies/fetchAvailablePoints",
    }),
    async fetch() {
      await this.fetchAvailablePoints();
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  background: #fff;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 576px;
  margin: auto;
}

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
  img {
    width: 30%;
  }
}

.bg-top {
  background: url("./images/momo_index_bg_top.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.title {
  width: 80%;
  margin: auto;
  /* padding: 30px 30px 50px 30px; */
  padding: 40px 20px 50px 20px;
  left: 10px;
  position: relative;
  img {
    width: 100%;
  }
}

.bg-bottom {
  background: url("./images/momo_index_bg_bottom.png");
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: -35px;
  padding-top: 20px;
  padding-bottom: 20px;
}

a:hover {
  text-decoration: none;
}

.button {
  position: relative;
  background: url("./images/momo_index_button_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  color: #fff;
  font-weight: bold;
  margin-bottom: 8px;
  justify-content: center;

  > div {
    font-size: 24px;
    padding: 6px;
    @media screen and (max-width: 320px) {
      font-size: 18px;
      padding: 3px;
    }
  }

  .button-caret {
    object-fit: cover;
    position: absolute;
    width: 12px;
    right: 20px;
    top: 18px;
    @media screen and (max-width: 320px) {
      width: 8px;
      right: 10px;
      top: 14px;
    }
  }
}

.layer {
  width: 60%;
  bottom: 10px;
  margin: auto;

  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
  img {
    width: 100%;
    margin-bottom: 10px;
  }

  .layer-point {
    position: relative;
    margin-bottom: 10px;

    .point-title {
      color: #2a4221;
      font-weight: bold;
      text-align: center;
      font-size: 24px;
      @media screen and (max-width: 320px) {
        font-size: 22px;
      }
    }
    .point {
      color: #2a4221;
      font-weight: bold;
      font-size: 50px;
      line-height: 1;
      background: #fff;
      padding: 5px 10px;
      border-radius: 15px;
      border: 2px solid #f5cf7a;
      text-align: center;

      @media screen and (max-width: 576px) {
        font-size: 36px;
      }
    }
  }
}
</style>
